// Dependencies
import React from 'react';
import { MapContext } from 'react-map-gl';

// Markers
import { ReactComponent as PartnerPin } from '../assets/markers/abhof_pin_partner.svg';
import { ReactComponent as NonPartnerPin } from '../assets/markers/abhof_pin_norm.svg';

const CustomMarker = ({ onClick, longitude, latitude, isPartner }) => {
  const context = React.useContext(MapContext);

  const [x, y] = context.viewport.project([longitude, latitude]);

  const markerStyle = {
    position: 'absolute',
    left: x - 12,
    top: y - 12,
    cursor: 'pointer',
    width: 24,
    height: 24,
  };

  return (
    <div style={markerStyle} onClick={onClick}>
      {isPartner ? <PartnerPin /> : <NonPartnerPin />}
    </div>
  );
};

export default CustomMarker;
