import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-dev.abhofladen.at/api/v1/vendors';

export const getMapData = async country => {
  try {
    const { data } = await axios.get(`${API_ENDPOINT}?has_coordinate=1${country && country !== 'com' ? `&country=${country}` : ''}`);
    return data.vendors || [];
  } catch (e) {
    throw new Error(e);
  }
};