export const formatMapData = vendorData =>
  vendorData
    .filter(
      item =>
        item.location
        && item.location.latitude
        && item.location.longitude
    )
    .map(
      item => ({
        name: item.name,
        latitude: item.location.latitude,
        longitude: item.location.longitude,
        isPartner: item.isPartner,
        rating: item.rating || 0,
        url: item.profileImage && item.profileImage.variants.length > 0 ? item.profileImage.variants[0].url : ''
      })
    );