// Dependencies
import React from 'react';

// Rating SVG
import { ReactComponent as StarRating } from '../assets/markers/abhof_bewertungsstern.svg';

const Popup = ({ info }) => (
  <div className="popup">
    {info.url && (
      <img className="popup-title_image" width={194} src={info.url} alt="info" />
    )}
    <div className="popup-title">{info.name}</div>
    <div className="popup-rating">
      {
        new Array(info.rating)
          .fill(1)
          .map((item, index) => (
            <div key={index} className="popup-rating_item">
              <StarRating />
            </div>
          ))
      }
      {
        new Array(5 - info.rating)
          .fill(1)
          .map((item, index) => (
            <div key={index} className="popup-rating_item">
              <StarRating fill="#D8D8D8" />
            </div>
          ))
      }
    </div>
  </div>
);

export default React.memo(Popup);