export const center = {
  de: {
    latitude: 51.703007,
    longitude: 10.422536,
    zoom: 6
  },
  at: {
    latitude: 48.004585,
    longitude: 15.168354,
    zoom: 7
  },
  com: {
    latitude: 50.257917,
    longitude: 10.885887,
    zoom: 5
  }
};
