// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import MapGL, {
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';

// Components
import Cluster from './components/Cluster';
import CustomMarker from './components/CustomMarker';
import PinGroup from './components/PinGroup';
import PopupInfo from './components/Popup';
import Loading from './components/Loading';

// Services
import { getMapData } from './service';

// Styles
import { geolocateStyle, fullscreenControlStyle, navStyle, scaleControlStyle } from './style';

// Utils
import { formatMapData } from './utils';

// Constants
import { center } from  './constants';
const TOKEN = process.env.REACT_APP_MAPBOX_TOKEN; // Set your mapbox token here

function App() {
  const [viewport, setViewport] = useState(center.com);
  const [popupInfo, setPopupInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const mapRef = useRef(null);
  
  useEffect(() => {
    const hostname = window.location.hostname.split(".")
    const country = hostname.length > 2 ? hostname[2] : 'com';
    setViewport(center[country]);
    getData(country);
  }, []);
  
  const getData = async (country) => {
    setLoading(true);
    setError(null);
    try {
      const data = await getMapData(country);
      setData(formatMapData(data));
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };
  
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Sorry but there are some problems to get data</div>;
  }

  return (
    <>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11?optimize=true"
        onViewportChange={setViewport}
        mapboxApiAccessToken={TOKEN}
        ref={mapRef}
      >
        

        {mapRef.current && (
          <Cluster
            map={mapRef.current.getMap()}
            radius={20}
            extent={512}
            nodeSize={40}
            element={clusterProps => (
              <PinGroup onViewportChange={setViewport} {...clusterProps} />
            )}
          >
            {/* every item should has a 
            unique key other wise cluster will not rerender on change */}
            {
              data.map((item, index) => (
                <CustomMarker
                  key={`marker-${index}`}
                  onClick={() => setPopupInfo(item)}
                  {...item}
                />
              ))
            }
            {/* points.map((point, i) => (
              <Marker
                key={i}
                longitude={point.longitude}
                latitude={point.latitude}
              >
                <div style={MarkerStyle} />
              </Marker>
            )) */}
          </Cluster>
        )}

        {popupInfo && (
          <Popup
            tipSize={5}
            anchor="top"
            longitude={popupInfo.longitude}
            latitude={popupInfo.latitude}
            closeOnClick={false}
            onClose={setPopupInfo}
          >
            <PopupInfo info={popupInfo} />
          </Popup>
        )}

        <GeolocateControl 
           style={geolocateStyle}
           positionOptions={{ enableHighAccuracy: true }}
           trackUserLocation={true}
           fitBoundsOptions={{maxZoom: 11}}
           auto       
        />
        <FullscreenControl style={fullscreenControlStyle} />
        <NavigationControl style={navStyle} />
        <ScaleControl style={scaleControlStyle} />
      </MapGL>
    </>
  );
}

export default App;
