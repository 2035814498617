// Dependencies
import React from 'react';

const PinGroup = ({ cluster }) => (
  <div className="cluster">
    {cluster.properties.point_count}
  </div>
);

export default PinGroup;
